import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "react-bootstrap/Button";

const PhotoRight = ({
	heading,
	text,
	img,
	cta,
	ctaUrl,
	height,
	imgAlt,
	sheading,
	width,
	bColour,
}) => {
	const newHeight = height || "";
	const newWidth = width || "";
	return (
		<section className="my-5 ">
			<Container>
				<Row className="align-items-center">
					<Col
						className="pe-lg-5 text-md-center text-lg-start pt-md-3 pt-lg-0"
						xs={12}
						lg={6}
					>
						<p className="text-start text-secondary mb-1">{sheading}</p>
						<h2 className=" text-start jost-bold text-primary mb-3">
							{heading}
						</h2>
						<p className="text-start">{text}</p>
						<Button
							variant={bColour}
							className={` w-100 w-md-auto cta-btn  mt-3 mt-lg-0 px-4 ${
								cta ? "" : "d-none"
							}`}
							as={Link}
							to={ctaUrl}
						>
							{cta}
						</Button>
					</Col>
					<Col
						xs={{ span: 12, order: "first" }}
						lg={{ span: 6, order: "last" }}
						className="mb-4 mb-md-0"
					>
						<GatsbyImage
							style={{ height: newHeight, maxWidth: newWidth }}
							alt={imgAlt}
							image={img}
						/>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default PhotoRight;
