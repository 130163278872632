import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";
import SVGCircle from "./SVG/circle";
import { GatsbyImage } from "gatsby-plugin-image";
import { SafeHtmlParser } from "./safeHtmlParser";
import { v4 as uuidv4 } from 'uuid';

const OurClasses = ({ classes, title, description, ctaButton }) => {
  return (
    <section className={` py-3`}>
      <Container>
        <Row className="align-items-lg-center">
          <Col className=" position-relative mb-4 mb-lg-0 text-center">
            <h2 className="text-primary">
              {title || "Classes in Eastbourne and Pevensey Bay"}
            </h2>
            <div className="mt-4 content-wrapper">
              <SafeHtmlParser htmlContent={description} />
            </div>
            <SVGCircle
              className="position-absolute top--40  d-none d-xl-block"
              style={{ width: "5rem", zIndex: -1, left: "calc(50% - 19rem)" }}
            />
          </Col>
          <Row className="mb-5 mt-4 mx-auto">
            {classes.map((class1) => (
              <Col className="text-center  mt-4" md={4} key={uuidv4()}>
                <Link
                  className="cta-link text-decoration-none"
                  to={`${class1.uri}`}
                >
                  {" "}
                  <GatsbyImage
                    style={{ maxHeight: "260px" }}
                    alt={class1.classFields.node?.altText}
                    image={
                      class1.classFields.featuredImage.node?.localFile
                        .childImageSharp.gatsbyImageData
                    }
                  />
                  <p className="jost-medium text-primary  mt-4">
                    {class1.title}
                  </p>
                </Link>
              </Col>
            ))}
          </Row>
          {ctaButton && ctaButton.url && (
            <Col xs={12} className="text-center ">
              <Button
                as={Link}
                variant="primary"
                size="lg"
                className=" cta-btn w-100 w-md-auto px-5"
                to={ctaButton.url}
              >
                {ctaButton.title}
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default OurClasses;
