import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import { v4 as uuidv4 } from 'uuid';

const Gallery = ({
	images
}) => {
	return (
		<section className="my-5 my-md-7 my-lg-10">
			<Container fluid>
				<Row className=" px-lg-0 ">
          {images.map(galleryItem => (
            <Col md={6} className="p-0" lg={4} key={uuidv4()}>
              <GatsbyImage
                style={{ height: "100%" }}
                alt={galleryItem?.galleryImage?.node?.altText}
                image={galleryItem?.galleryImage?.node?.localFile.childImageSharp.gatsbyImageData}
              />
            </Col>
          ))}
				</Row>
			</Container>
		</section>
	);
};

export default Gallery;
