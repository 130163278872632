import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import { MdPlayArrow } from "@react-icons/all-files/md/MdPlayArrow";
import { SafeHtmlParser } from "./safeHtmlParser";
import { v4 as uuidv4 } from 'uuid';

const Faq = ({ hideHeader, title, faqs }) => {
  //   const faqs = [
  //     {
  //       question: "WILL PILATES HELP WITH MY ONGOING PAIN OR INJURY?",
  //       answer:
  //         "Yes!  It’s wonderful for rehab of an injury and can help prevent future injuries by correcting imbalances you may have. If you have a medical condition we can ensure that you are safe when you start practicing. So whether you have a work or sports injury, or want to start moving after not doing any exercise for years, Pilates can definitely help.",
  //     },
  //     {
  //       question: "WHAT SHOULD I WEAR AND BRING WITH ME?",
  //       answer:
  //         "Leggings or tracksuit bottoms with a comfortable top  are perfect Pilates clothes. You just need to bring a mat to exercise on. Beginning your Pilates journey doesn't have to be expensive or complicated.",
  //     },
  //     {
  //       question: "HOW SOON WILL I SEE RESULTS?",
  //       answer:
  //         "Many people say that their back pain is better after just one session!  However it’s very important that the exercises are performed correctly which is why at Eastbourne Pilates we encourage you to start with one to ones or in a small group.",
  //     },
  //     {
  //       question: "I'M OVER 50, CAN I STILL DO PILATES?",
  //       answer:
  //         "Yes! Teaching the over fifties is my speciality and I would love to show you how much it can improve your life!",
  //     },
  //   ];
  return (
    <section id="faq" className="my-6 position-relative">
      <div
        style={{ zIndex: "-3" }}
        className=" h-100 w-100 position-absolute"
      ></div>
      <Container>
        <Row className={` pt-5 ${hideHeader && "d-none"}`}>
          <Col className="text-center">
            <h2 className="text-primary mb-3">{title || "FAQ"}</h2>
          </Col>
        </Row>
        <Row className="pb-5">
          {faqs.map((faq) => (
            <Col xs={12} className="my-1 mx-0 px-0" key={uuidv4()}>
              <Accordion>
                <Card className="border-0">
                  <Card.Header className="border-0 bg-light-background px-0 p-0 m-0">
                    <ContextAwareToggle
                      eventKey="0"
                      className="bg-light-background border-0 "
                    >
                      <p className="pb-0 mb-0" style={{ fontWeight: "600" }}>
                        {faq.homeFaqQuestion}
                      </p>
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="pt-0 bg-light-background">
                      <Row>
                        <Col
                          className="ps-1 fw-light"
                          xs={{ span: 11, offset: 1 }}
                        >
                          <SafeHtmlParser htmlContent={faq.homeFaqAnswer} />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <hr className="bg-black" />
              </Accordion>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Faq;

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const [turnArrow, setTurnArrow] = useState(false);

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback && callback(eventKey);
    setTurnArrow(!turnArrow);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header
      className="border-0"
      style={{
        color: isCurrentEventKey ? "#fbfbfb" : "black",
        backgroundColor: isCurrentEventKey ? "black" : "#fbfbfb",
      }}
      onClick={decoratedOnClick}
    >
      <Container className="pe-0 ps-1" fluid={true}>
        <Row className="align-items-center">
          <Col xs={1} className="d-flex px-0  justify-content-start">
            <MdPlayArrow
              className={`position-relative fs-5 text-primary cheveron  ${
                turnArrow ? "cheveron-down" : ""
              }`}
            />
          </Col>
          <Col
            className="d-flex justify-content-start align-items-center ps-0"
            xs={11}
          >
            {children}
          </Col>
        </Row>
      </Container>
    </Card.Header>
  );
}
