import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Faq from "../components/faq";
import OurClasses from "../components/our-classes";
import PhotoLeft from "../components/photo-left";
import PhotoRight from "../components/photo-right";
import Gallery from "../components/gallery";
import Link from "../components/link";
import Button from "react-bootstrap/Button";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import SVGRing from "../components/SVG/ring";
import SVGCircle from "../components/SVG/circle";
import SVGSVGRingCircle from "../components/SVG/ring-circle";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import { v4 as uuidv4 } from "uuid";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			classes: allWpClass {
				nodes {
					title
					id
					uri
					classFields {
						featuredImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "homepage" }) {
				homepageFields {
					homeBanner {
						bannerTitle
						bannerDescription
						bannerVideoButtonText
						bannerVideo {
							node {
								altText
								mediaItemUrl
							}
						}
						bannerSlideImages {
							bannerSlideImage {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						bannerSmallTitle
						bannerSmallText
						bannerSmallLink {
							title
							target
							url
						}
						bannerSmallImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					homeOurClasses {
						ourClassesHeading
						ourClassesDescription
						ourClassesButton {
							title
							target
							url
						}
					}
					homeGallery {
						galleryImages {
							galleryImage {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					homeLeftImageRightContent {
						homeRightTitle
						homeRightContent
						homeLeftImageRightContentButton {
							title
							target
							url
						}
						homeLeftImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					homeSimpleContentSection {
						homeSimpleContentText
					}
					homeRightImageLeftContent {
						homeLeftTitle
						homeLeftContent
						homeRightImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					homeBenefitsSection {
						homeBenefitsTitle
						homeBenefitsItems {
							benefitsText
							benefitsTitle
						}
						homeBenefitsButton {
							title
							target
							url
						}
					}
					homeFaq {
						homeFaqHeading
						homeFaqItems {
							homeFaqQuestion
							homeFaqAnswer
						}
					}
					homeLocation {
						homeLocationHeading
						homeLocations {
							homeLocationAddress
							homeLocationEmbeddedCode
						}
					}
				}
				seoFields {
					metaTitle
					metaDescription
					opengraphTitle
					opengraphDescription
					productSchema
					image {
						node {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFields, homepageFields },
	} = data;

	const {
		homeBanner,
		homeOurClasses,
		homeGallery,
		homeLeftImageRightContent,
		homeSimpleContentSection,
		homeRightImageLeftContent,
		homeBenefitsSection,
		homeFaq,
		homeLocation,
	} = homepageFields;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<LocalBusinessSchema />
			<GatsbySeo
				title={seoFields?.metaTitle}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.localFile.publicURL}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			{homeBanner && !checkPropertiesForNull(homeBanner, ["bannerTitle"]) && (
				<Hero
					title={homeBanner?.bannerTitle}
					description={homeBanner?.bannerDescription}
					sliderImages={homeBanner?.bannerSlideImages}
					imgSmall={
						homeBanner?.bannerSmallImage.node?.localFile.childImageSharp
							.gatsbyImageData
					}
					imgSmallAlt={homeBanner?.bannerSmallImage.node?.altText}
					url={homeBanner?.bannerSmallLink.url}
					btnTxt={homeBanner?.bannerSmallLink.title}
					smallTxt={
						<SafeHtmlParser htmlContent={homeBanner?.bannerSmallText} />
					}
					smallHeading={homeBanner?.bannerSmallTitle}
					videoButtonText={homeBanner?.bannerVideoButtonText}
					video={homeBanner?.bannerVideo.node?.mediaItemUrl}
				/>
			)}
			{homeOurClasses &&
				!checkPropertiesForNull(homeOurClasses, ["ourClassesHeading"]) && (
					<section className="position-relative">
						<OurClasses
							title={homeOurClasses?.ourClassesHeading}
							description={homeOurClasses?.ourClassesDescription}
							classes={data.classes?.nodes}
							ctaButton={homeOurClasses?.ourClassesButton}
						/>
						<SVGRing
							style={{ width: "4rem", zIndex: -1 }}
							className="position-absolute end-20 bottom--10 d-none d-xl-block"
						/>
					</section>
				)}
			{homeGallery &&
				homeGallery.galleryImages &&
				homeGallery.galleryImages.length > 0 && (
					<section>
						<Gallery images={homeGallery.galleryImages} />
					</section>
				)}

			{homeLeftImageRightContent &&
				!checkPropertiesForNull(homeLeftImageRightContent, [
					"homeRightTitle",
				]) && (
					<section className="my-7 position-relative">
						<SVGSVGRingCircle
							style={{ left: "53%", top: "-12%", width: "3rem", zIndex: -1 }}
							className="position-absolute  d-none d-xl-block "
						/>
						<PhotoLeft
							img={
								homeLeftImageRightContent?.homeLeftImage.node?.localFile
									.childImageSharp.gatsbyImageData
							}
							imgAlt={homeLeftImageRightContent?.homeLeftImage.node?.altText}
							heading={homeLeftImageRightContent?.homeRightTitle}
							text={
								<SafeHtmlParser
									htmlContent={homeLeftImageRightContent?.homeRightContent}
								/>
							}
							cta={
								homeLeftImageRightContent?.homeLeftImageRightContentButton
									?.title
							}
							ctaUrl={
								homeLeftImageRightContent?.homeLeftImageRightContentButton?.url
							}
						/>
					</section>
				)}
			{homeSimpleContentSection &&
				!checkPropertiesForNull(homeSimpleContentSection, [
					"homeSimpleContentText",
				]) && (
					<section>
						<Container>
							<Row>
								<Col>
									<SafeHtmlParser
										htmlContent={
											homeSimpleContentSection?.homeSimpleContentText
										}
									/>
								</Col>
							</Row>
						</Container>
					</section>
				)}
			{homeRightImageLeftContent &&
				!checkPropertiesForNull(homeRightImageLeftContent, [
					"homeLeftTitle",
				]) && (
					<section className="pt-xl-3">
						<PhotoRight
							height="23rem"
							img={
								homeRightImageLeftContent.homeRightImage?.node?.localFile
									.childImageSharp.gatsbyImageData
							}
							imgAlt={homeRightImageLeftContent.homeRightImage?.node?.altText}
							heading={homeRightImageLeftContent?.homeLeftTitle}
							text={
								<SafeHtmlParser
									htmlContent={homeRightImageLeftContent?.homeLeftContent}
								/>
							}
						/>
					</section>
				)}

			{homeBenefitsSection &&
				!checkPropertiesForNull(homeBenefitsSection, ["homeBenefitsTitle"]) && (
					<section
						className="py-6"
						style={{
							background:
								"transparent linear-gradient(180deg, #E0F1F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
							opacity: 1,
						}}
					>
						<Container>
							<Row>
								<Col className="text-center position-relative">
									<SVGCircle
										className="position-absolute d-none d-xl-block"
										style={{ top: "-6%", left: "3%" }}
										fill="#D4EEF2"
									/>
									<h3
										style={{ zIndex: 999 }}
										className=" position-relative text-start jost-bold mb-5 text-primary"
									>
										{homeBenefitsSection?.homeBenefitsTitle}
									</h3>
									{homeBenefitsSection.homeBenefitsItems &&
										homeBenefitsSection.homeBenefitsItems.length > 0 && (
											<>
												{homeBenefitsSection.homeBenefitsItems.map((item) => (
													<div className="text-start mb-5" key={uuidv4()}>
														<span className="jost-medium text-secondary fs-5">
															{item.benefitsTitle}
														</span>{" "}
														<SafeHtmlParser htmlContent={item?.benefitsText} />
													</div>
												))}
											</>
										)}

									{homeBenefitsSection.homeBenefitsButton &&
										homeBenefitsSection.homeBenefitsButton.url && (
											<Button
												className="cta-btn px-5 w-100 w-md-auto"
												as={Link}
												to={homeBenefitsSection.homeBenefitsButton.url}
											>
												{homeBenefitsSection.homeBenefitsButton.title}
											</Button>
										)}
								</Col>
							</Row>
						</Container>
					</section>
				)}

			{homeFaq && !checkPropertiesForNull(homeFaq, ["homeFaqItems"]) && (
				<Faq title={homeFaq?.homeFaqHeading} faqs={homeFaq?.homeFaqItems} />
			)}
			{homeLocation &&
				!checkPropertiesForNull(homeLocation, ["homeLocations"]) && (
					<section className="bg-contactBg py-5">
						<Container id="locations">
							<Row>
								<Col>
									<h3 className="jost-regular text-center mb-5">
										{homeLocation?.homeLocationHeading}
									</h3>
								</Col>
							</Row>
							{homeLocation.homeLocations &&
								homeLocation.homeLocations.length > 0 && (
									<Row className="gy-5">
										{homeLocation.homeLocations.map((item, i) => (
											<Col
												className="text-center d-flex flex-column justify-content-between"
												lg={6}
												key={uuidv4()}
											>
												<div className="mb-1">
													<SafeHtmlParser
														htmlContent={item.homeLocationAddress}
													/>
												</div>

												<iframe
													style={{ height: "50vh" }}
													src={item.homeLocationEmbeddedCode}
													frameBorder="0"
													aria-hidden="false"
													title="google-map"
													width="100%"
													loading="lazy"
												></iframe>
											</Col>
										))}
									</Row>
								)}
						</Container>
					</section>
				)}
		</Layout>
	);
};

export default IndexPage;
